<template>
  <div class="container-pc">
    <div class="info-box">
      <div class="info-view Global-W">
        <div :class="['info-left', { 'cate-skeleton': isShowSkeleton }]">
          <div class="info-swiper">
            <!-- <el-carousel @change="handleChangeCarousel" :interval="5000" arrow="always" height="544px"
              :initial-index="initial">
              <el-carousel-item v-for="(item, index) in proInfo.listImgs" :key="index">
                <img :src="item.listImgpath" alt="" />
              </el-carousel-item>
            </el-carousel> -->
            <a-carousel :after-change="handleChangeCarousel" arrows ref="carousel">
              <template #prevArrow>
                <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                  <left-circle-outlined />
                </div>
              </template>
              <template #nextArrow>
                <div class="custom-slick-arrow" style="right: 10px">
                  <right-circle-outlined />
                </div>
              </template>
              <div v-for="(item, index) in proInfo.listImgs" :key="index">
                <img :src="item.listImgpath" alt="" />
              </div>
            </a-carousel>
          </div>
          <div class="info-imgs">
            <div :class="['info-imgs-view', initial == index ? 'isShowimgs' : '']"
              v-for="(item, index) in proInfo.listImgs" :key="index" @click="handleChangeCarousel(index)">
              <img :src="item.listImgpath" alt="">
            </div>
          </div>
        </div>
        <div class="info-right">
          <div :class="[{ 'ISABSOLUTE': isFixed }, 'info-pro']">
            <div :class="[
          'info-xilie',
          'h50',
          { 'cate-skeleton': isShowSkeleton },
        ]">
              {{ proInfo.proSeries }}
            </div>
            <div :class="['info-name', '', { 'cate-skeleton': isShowSkeleton }]">
              {{ proInfo.proName }}
            </div>
            <div :class="[
          'info-price',
          'h50',
          { 'cate-skeleton': isShowSkeleton },
        ]">
              ${{ proInfo.salePrice }}
            </div>
            <div :class="['info-img', { 'cate-skeleton': isShowSkeleton }]" v-if="proInfo.listImgs">
              <img :src="proInfo?.listImgs[0]?.listImgpath" alt="" />
            </div>
            <div :class="['info-btns', { 'cate-skeleton': isShowSkeleton }]">
              <!-- <el-button color="#414141" size="large" type="primary" @click="handleShowBack">立即购买</el-button> -->
              <a-dropdown>
                <!-- <a class="ant-dropdown-link" @click.prevent> -->
                <el-button color="#414141" size="large" type="primary" v-if="good">
                  Buy Now
                  <span class="iconfont icon-yamaxun1"></span>
                </el-button>
                <!-- </a> -->
                <template #overlay>
                  <a-menu>
                    <p class="menu-title">Please choose <br /> your desired country：</p>
                    <a-menu-item v-for="(item, index) in good.links" :key="index">
                      <a :href="item.url" target="_blank" class="a-item" @click="handleGoCountry(item)">
                        <img src="../../assets/icon/DE.png" class="guoqi" v-if="item.country == 'DE'">
                        <img src="../../assets/icon/FR.png" class="guoqi" v-if="item.country == 'FR'">
                        <img src="../../assets/icon/US.png" class="guoqi" v-if="item.country == 'US'">
                        <img src="../../assets/icon/JP.png" class="guoqi" v-if="item.country == 'JP'">
                        {{ item.country }}</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>


              <el-button size="large" color="#ffffff" type="default" @click="handleSubmitOrder">
                <span class="t-icon t-icon-Paypal PAYAPL"></span>
              </el-button>
              <el-button color="#ffffff" size="large" type="success" @click="handleAddCarts">Add Cart</el-button>
            </div>
          </div>
        </div>
      </div>



      <!-- 详情区域 -->
      <div v-if="proInfo.detailImgs">
        <TemplateOne v-if="proInfo.proTemplate.templateId === 2" :data="proInfo.detailImgs"
          :template="proInfo.proTemplate" />
        <TemplateTwo v-if="proInfo.proTemplate.templateId === 1" :data="proInfo.detailImgs"
          :template="proInfo.proTemplate" />
        <TemplateDetail v-if="proInfo.proTemplate.templateId === 0" :data="proInfo.detailImgs" />
      </div>
      <!-- 详情区域 -->

    </div>
  </div>

  <!-- m -->
  <div class="container-m">
    <div class="swiper-m">
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="#414141">
        <van-swipe-item v-for="(item, index) in proInfo.listImgs" :key="index">
          <img :src="item.listImgpath" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="info-m">
      <div>
        <div class="info-m-xilie">{{ proInfo.proSeries }}</div>
        <div class="info-m-name">{{ proInfo.proName }}</div>
        <div class="info-m-price">${{ proInfo.salePrice }}</div>
      </div>
      <!-- <div class="info-btn">ADD TO BAG</div> -->
      <div class="info-m-details">
        <van-image class="image" :src="i.detailImgpath" v-for="(i, index) in proInfo.detailImgs" :key="index"
          lazy-load />
      </div>
    </div>
    <van-action-bar>
      <!-- <van-action-bar-icon icon="chat-o" text="客服" @click="goPage" /> -->
      <van-action-bar-icon icon="cart-o" text="Cart" @click="goPage('Carts')" />
      <van-action-bar-button color="#414141" type="warning" text="Buy Now" @click="handleShowBack" />
      <!-- Buy Now
        <span class="iconfont icon-yamaxun1"></span>
      </van-action-bar-button> -->
      <van-action-bar-button color="#414141" type="danger" text="CHECKOUT" @click="handleSubmitOrder" />
    </van-action-bar>
  </div>
  <van-popup v-model:show="isShowBack" position="bottom" round :style="{ height: '30%' }" closeable>
    <div class="back-view">
      <p>Please choose your desired country:</p>
      <div class="back-item" v-for="(item, index) in good.links" :key="index">
        <a :href="item.url" target="_blank" class="a-item-m">
          <img src="../../assets/icon/DE.png" class="guoqi" v-if="item.country == 'DE'">
          <img src="../../assets/icon/FR.png" class="guoqi" v-if="item.country == 'FR'">
          <img src="../../assets/icon/US.png" class="guoqi" v-if="item.country == 'US'">
          <img src="../../assets/icon/JP.png" class="guoqi" v-if="item.country == 'JP'">
          {{ item.country }}</a>
      </div>
    </div>
  </van-popup>
  <van-back-top bottom="150" />
  <!-- 弹窗 -->
</template>

<script setup>
import { onMounted, ref } from "vue";
import TemplateOne from "@/components/info/Template-one.vue";
import TemplateTwo from "@/components/info/Template-two.vue";
import TemplateDetail from "@/components/info/Template-Detail.vue";
import requestAsync from "@/utils/request";
import { useRoute } from "vue-router";
import { ElLoading } from 'element-plus';
import { LeftCircleOutlined, RightCircleOutlined, LeftCircleTwoTone } from '@ant-design/icons-vue';

import goods from '@/utils/goods'
import CountlyEvent from '../../utils/countlyEvent'
import router from "@/router/router";

import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'
const countly = new CountlyEvent()

const route = useRoute()

let proInfo = ref({});
let isFixed = ref(false)
let initial = ref(0)
let carousel = ref('')
let isShowBack = ref(false)
const activeNamesM = ref(["1"])
let isShowSkeleton = ref(true)

const good = ref()
const isSubmitPayPal = ref(true)
const loadingInstance = ref(null)

onMounted(() => {
  const id = route.params.id
  getInfoData(id)
  scrollTop()
  goods.forEach(item => {
    if (item.id == id) {
      good.value = item
    }
  })
})
const scrollTop = () => {
  window.scrollTo(0, 0); // 将页面滚动到顶部
}

/* 触底加载 */
const handleScroll = () => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const scrollBottom = getScrollBottom()
  if (scrollTop > 400 && scrollBottom > 260) {
    isFixed.value = true
  } else {
    isFixed.value = false
  }
}

const getScrollBottom = () => {
  // 视口高度
  const viewportHeight = window.innerHeight;
  // 文档高度
  const documentHeight = document.documentElement.scrollHeight;
  // 滚动条位置
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

  // 滚动条距离底部的位置
  return documentHeight - viewportHeight - scrollTop;
}



const getInfoData = async (id) => {
  const data = await requestAsync({
    url: "/proProduct/details",
    data: {
      proCode: id,
    },
  });
  proInfo.value = data.data.proProductJson;
  setTimeout(() => {
    isShowSkeleton.value = false;
  }, 100)
  /* 新增countly */
  const countlyData = {
    id: id,
    name: proInfo.value.proName,
    url: window.location.href
  }
  countly.saveInfoId(countlyData)
  /* 新增countly */
  if (proInfo.value.proTemplate.templateId === 0) {
    window.addEventListener('scroll', handleScroll, true)
  }
}


const handleChangeCarousel = (event) => {
  initial.value = event
  carousel.value.goTo(event);
}

const handleGoCountry = (info) => {
  /* 新增countly */
  const countlyData = {
    id: route.params.id,
    name: proInfo.value.proName,
    country: info.country,
    countryUrl: info.url
  }
  countly.saveGoAmazon(countlyData)
  /* 新增countly */
}

const handleShowBack = () => {
  isShowBack.value = !isShowBack.value
}


/* 去购买 */
const handleSubmitOrder = async () => {
  if (!Cookies.get('uid')) {
    router.replace({
      name: 'Login',
      query: { redirect: route.fullPath }
    })
    return
  }
  const shopItem = {
    proCode: proInfo.value.proCode,
    proNum: 1,
    proPrice: proInfo.value.salePrice,
    proImg: proInfo.value.listImgs[0].listImgpath,
    proName: proInfo.value.proName,
    proSeries: proInfo.value.proSeries
  }

  const proList = []
  proList.push(shopItem)
  Cookies.set('shopping', JSON.stringify(proList))
  router.push({
    name: 'Order',
  })
}





const handleAddCarts = () => {
  const shopCart = JSON.parse(Cookies.get('shopCart') || '[]')
  const shopItem = {
    proCode: proInfo.value.proCode,
    proNum: 1,
    proPrice: proInfo.value.salePrice,
    proImg: proInfo.value.listImgs[0].listImgpath,
    proName: proInfo.value.proName,
    proSeries: proInfo.value.proSeries
  }
  const productIndex = shopCart.findIndex(p => p.proCode === shopItem.proCode)
  if (productIndex >= 0) {
    shopCart[productIndex].proNum++
  } else {
    shopCart.push(shopItem)
  }
  Cookies.set('shopCart', JSON.stringify(shopCart))
  router.push({
    name: 'Carts'
  })
}




</script>

<style lang="scss" scoped>
.container-pc {
  position: relative;
}

.info-box {
  background-color: #f5f6f7;

  .info-view {
    display: flex;
    width: 100%;
    font-size: 15px;

    .info-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 750px;

      .info-swiper {
        width: 544px;
      }

      .info-details {
        width: 750px;
        margin-top: 50px;

        .image {
          width: 100%;
          height: auto;
        }
      }

      .info-imgs {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;
        margin-top: 10px;

        .info-imgs-view {
          width: 60px;
          height: 60px;
          padding: 3px;
          border: 1px solid #ccc;
          border-radius: 10px;
          margin: 0 5px;
        }

        .isShowimgs {
          border: 1px solid #414141;
        }
      }
    }

    .info-right {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      padding: 30px 50px;
      height: auto;

      .h50 {
        height: 40px;
        line-height: 40px;
        margin: 20px 0;
      }

      .info-xilie {
        font-size: 20px;
      }

      .info-name {
        font-size: 20px;
        font-weight: 700;
      }

      .info-price {
        font-size: 25px;
        // color: $SelectColor;
      }

      .info-img {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #414141;
      }

      .info-btns {
        width: 100%;
        margin: 30px 0;
      }
    }
  }
}

.container-m {
  width: 100%;
  font-size: 0.18rem;

  .info-m {
    width: 100%;
    padding: 0 0.2rem;

    .info-m-xilie,
    .info-m-name,
    .info-m-price {
      // height: 0.5rem;
      line-height: 0.5rem;
      font-size: 0.25rem;
      text-align: center;
    }

    .info-m-price {
      font-size: 0.35rem;
      font-weight: 700;
      // color: $SelectColor;
    }

    .info-btn {
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      text-align: center;
      font-weight: 700;
      background-color: $SelectColor;
      color: #fff;
      border-radius: 0.1rem;
      font-size: 0.25rem;
      margin: 0.3rem 0;
    }
  }

  .info-m-details {
    .image {
      display: block;
    }

    margin-top: .5rem;

  }


}

.back-view {
  width: 100%;
  padding: 20px;
  padding-top: .8rem;
  font-size: 0.30rem;




}

.menu-title {
  font-size: 16px;
  padding: 0 10px;
  margin-bottom: 5px;
}

.a-item {
  display: flex;
  margin: 5px 0;
}

.a-item-m {
  display: flex;
  margin: 20px 0;
}

.guoqi {
  width: 20px;
  margin-right: .3rem;
}







:deep() {
  .van-back-top {
    background-color: #414141 !important;
  }
}

/* For demo */
:deep(.slick-slide) {
  text-align: center;
  overflow: hidden;
}

:deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #999;
  // background-color: rgba(31, 45, 61, 0.11);
  transition: ease all 0.3s;
  opacity: 0.3;
  z-index: 1;
}

:deep(.slick-arrow.custom-slick-arrow:before) {
  display: none;
}

:deep(.van-swipe__indicator) {
  background-color: #414141;
}

:deep(.slick-slide h3) {
  color: #fff;
}

:deep(.van-action-bar) {
  z-index: 999;
}

.icon-yamaxun1 {
  font-size: 25px;
  margin-left: 10px;
  margin-top: 6px;
}
</style>
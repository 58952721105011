const goods = [
    {
        id: '10010310044',
        name: '不倒翁甜心猫',
        links: [{
            country: 'JP',
            url: 'https://www.amazon.co.jp/dp/B0CDBXNDVG'
        }]
    },
    {
        id: '1011030121',
        name: '水滴-红色',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CDC5W659'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CDH2L5WN'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.fr/dp/B0CDH2L5WN'
            },
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CNG51KDS'
            },
        ]
    },
    {
        id: '10010310111',
        name: '钟情-红色',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CD7T1FRB'
            },
        ]
    },
    {
        id: '1011210025',
        name: '甜心兔-灰色-中号',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CDBZ55H5'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CDGYJZ95'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.fr/dp/B0CDGYJZ95'
            },
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CNG8YQSQ'
            },
        ]
    },
    {
        id: '10010310117',
        name: '小钟情',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CDC7Q9CJ'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CDW5X4RH'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.fr/dp/B0CDW5X4RH'
            },
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CND29SLV'
            },
        ]
    },
    {
        id: '10010310068',
        name: '星云-红色-中号',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CDBDPFMT'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CDGW6K7T'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.fr/dp/B0CDGW6K7T'
            },
        ]
    },
    {
        id: '10010310116',
        name: '小圆满',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CP2F835N'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CPFFKLMZ'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.fr/dp/B0CPFFKLMZ'
            },
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CNGLX2CH'
            },
        ]
    },
    {
        id: '10010310120',
        name: '圆满',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CP2CFD6T'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CPFGG2VR'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CPFGG2VR'
            }
        ]
    },
    {
        id: '10010310190',
        name: '圆满满天星',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CP2HKGK7'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CPFFR1B8'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CPFFR1B8'
            }
        ]
    },
    {
        id: '10010310257',
        name: '甜心兔-灰色-小号',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CP2G6WPX'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CM6FMDP5'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CM6FMDP5'
            },
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CNGJY2JT'
            },
        ]
    },
    {
        id: '10010310189',
        name: '甜心兔-白色-小号',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CP2GZWFZ'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CPFD6M3D'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CPFD6M3D'
            }
        ]
    },
    {
        id: '1011030110',
        name: '水滴-粉色',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CP2HQGG6'
            },
        ]
    },
    {
        id: '1011030111',
        name: '水滴-蓝色',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CP2HDF3L'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dpB0CM6PLNZB'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dpB0CM6PLNZB'
            },
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CNGN47DS'
            },
        ]
    },
    {
        id: '1011030119',
        name: '水滴-紫色',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CP2H5B8C'
            },
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CM6FG9HR'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CM6FG9HR'
            },
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CNG6XPX8'
            },
        ]
    },
    {
        id: '1011030113',
        name: '水滴-浅紫色',
        links: [
            {
                country: 'JP',
                url: 'https://www.amazon.co.jp/dp/B0CP2JBSJZ'
            },
        ]
    },
    {
        id: '10010310112',
        name: '钟情丘比特',
        links: [
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CDGLKFNM'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CDGLKFNM'
            },
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CNSQF9BL'
            },
        ]
    },
    {
        id: '1011210024',
        name: '不倒翁甜心兔',
        links: [
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CDGR4BX6'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CDGR4BX6'
            },
        ]
    },
    {
        id: '1011030120',
        name: '水滴-黑色',
        links: [
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CM6L96QH'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CM6L96QH'
            },
        ]
    },
    {
        id: '10010310212',
        name: '独角兽--中号-灰色',
        links: [
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CPFJ2SRB'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CPFJ2SRB'
            },
        ]
    },
    {
        id: '1011210033',
        name: '告白熊-红色',
        links: [
            {
                country: 'DE',
                url: 'https://www.amazon.de/dp/B0CPFKJV1F'
            },
            {
                country: 'FR',
                url: 'https://www.amazon.FR/dp/B0CPFKJV1F'
            },
        ]
    },
    {
        id: '10010310187',
        name: '小圆满满天星',
        links: [
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CNGM7HZM'
            },
        ]
    },
    {
        id: '10010310043',
        name: '心动红色',
        links: [
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CYC453D5'
            },
        ]
    },
    {
        id: '10010310282',
        name: '心动粉色',
        links: [
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CYCC6XL7'
            },
        ]
    },

    {
        id: '10010310082',
        name: '一见倾心',
        links: [
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CYCDG59N'
            },
        ]
    },
    {
        id: '10010310303',
        name: '甜心熊 mini',
        links: [
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0D1JWXQVC'
            },
        ]
    },
    {
        id: '10010310282',
        name: '心动粉色',
        links: [
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0CYCC6XL7'
            },
        ]
    },
    {
        id: '10010310304',
        name: '甜心兔 mini',
        links: [
            {
                country: 'US',
                url: 'https://www.amazon.com/dp/B0D1JXCQPL'
            },
        ]
    },
]

export default goods